import Drawer from '@mui/material/Drawer';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import { Button } from '../../components/ui';
import { getAttendanceList } from '../../api/attendance';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import usePut from '../../hooks/put/usePut';
import toast, { Toaster } from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';



const presentDefinition = {
    'pending': 'bg-yellow-100 text-yellow-700',
    'under process': 'bg-orange-100 text-orange-700',
    'absent': 'bg-red-100 text-red-700',
    'present': 'bg-green-100 text-green-700',
    'half_day': 'bg-fuchsia-100 text-fuchsia-70',
    'weekend': 'bg-blue-100 text-blue-700',
    'approved_leave': 'bg-indigo-200 text-indigo-700',
    'missed_punch': 'bg-purple-200 text-purple-700'
}

// present
// absent
// half_day
// holiday
// weekend_full_day
// weekend_first_half
// weekend_second_half
// on_approved_leave
const paymentDefinition = [
    {
        value: 'present',
        label: 'Present'
    },
    {
        value: 'pending',
        label: 'Pending'
    },
    {
        value: 'absent',
        label: 'Absent'
    },
    {
        value: 'half_day',
        label: 'Half day'
    },
    {
        value: 'holiday',
        label: 'Holiday'
    },
    {
        value: 'weekend_full_day',
        label: 'Weekend full day'
    },
    {
        value: 'weekend_first_half',
        label: 'Weekend first half'
    },
    {
        value: 'weekend_second_half',
        label: 'Weekend second half'
    },
    {
        value: 'on_approved_leave',
        label: 'On approved leave'
    },
    
    {
        value: 'missed_punch',
        label: 'Missed Punch'
    },

]

const PaymentLog = ({
    open,
    onClose,
    id
}) => {
    let [searchParams, setSearchParams] = useSearchParams();
    let initialData = {
        month_and_year:new Date(searchParams.get('date')) || new Date(),
        user_id: id,
        screen: 'salary'
    }
    const [attendanceList, setAttendanceList] = useState([])
    const [isFetching, setIsFetching] = useState(false)
    const [isDataFound, setDataFound] = useState(true)
    useEffect(() => {
        handleCalculateSalary()
    }, [])
    const handleCalculateSalary = () => {
        setDataFound(true)
        setIsFetching(true)
        getAttendanceList(initialData)
            .then((res) => {
                if (res.status === 200) {
                    setAttendanceList(res.data)
                    if (res.data.length <= 0) {
                        setDataFound(false)
                    }
                    setIsFetching(false)
                }
            })
            .catch(err => {
                alert('Something went wrong!')
                setIsFetching(false)
            })
    }
    const updatePayment = usePut({
        url: 'updatePaymentDefination',
        refreshUrl:'getPayoutList',
        onSuccess: (data) => {
            toast.success('Payment log updated')
        },
        onError: () => { },
        onSettled: () => { }
    })


    const handleChangePaymentDefination = (e, idx, data) => {
        let value = e.target.value
        const updatedList = attendanceList.map((item,id) => {
            if (id === idx) {
                return { ...item, payment_definition: value };
            }
            return item;
        });
        setAttendanceList(updatedList);

        const fillData = {
            ...data,
            user_id: id,
            payment_definition: value
        }
        // console.log(fillData);

        updatePayment.handleAuth.mutate(fillData)
    }

    return (
        <>
        <Toaster/>
            <Drawer anchor={'right'} open={open} onClose={onClose}>
                <div className='h-full bg-white px-8 min-h-[120vh]' style={{ width: 'calc(100vw - 250px)' }}>
                    <form className=' space-y-8'>
                        <div className='flex items-center justify-between  py-5 border-b border-gray-200 sticky z-40 top-0 bg-white'>
                            <div className='flex items-center space-x-3'>
                                <ArrowLeftIcon className=' text-gray-700 w-8 h-8 cursor-pointer' onClick={onClose} />
                                <p className='text-gray-700 text-3xl font-semibold'>Salary Log for <Moment date={new Date()} format='MMMM-YYYY' /> </p>
                            </div>
                        </div>

                        <div>
                            <div className=' grid grid-cols-7 gap-1'>
                                {attendanceList.map((data, idx) => {
                                    const { attendance_date, present_definition, payment_definition, punch_time } = data || {}
                                    return (
                                        <div key={idx} className='w-full border p-2 relative rounded flex flex-col justify-between'>
                                            <div className='flex items-center justify-between'>
                                                <p className='bg-black text-white w-6 h-6 text-xs rounded-full flex items-center justify-center'>{attendance_date}</p>
                                                <span className={`text-xs capitalize  px-2 rounded 0 ${presentDefinition[present_definition]}`}>
                                                    {present_definition.replace(/_/g, ' ')}
                                                </span>
                                            </div>
                                            <div className='py-3'>
                                                <small className='block'>Punch timing</small>
                                                <small className=' font-semibold block'>{punch_time}</small>
                                            </div>
                                            <select disabled={present_definition === 'pending'} onChange={(e) => handleChangePaymentDefination(e, idx, data)} value={payment_definition} name="salaryLog" className='w-[90%] border py-1 rounded border-gray-200  text-xs disabled:cursor-not-allowed'> 
                                                {paymentDefinition?.map((list, idx) => {
                                                    return <option value={list?.value} key={idx}>
                                                        {list?.label}
                                                    </option>
                                                })}
                                            </select>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </form>
                </div>
            </Drawer>
        </>
    )
}

export default PaymentLog